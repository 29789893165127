<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="invoice-add-wrapper">
          <div class="row invoice-add">
            <div class="col-12">
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding pb-0">
                  <h4 class="invoice-title mb-1">Новый возврат</h4>
                  <div class="row">
                    <div class="col-12 text-right">
                      <router-link class="btn btn-outline-secondary mr-2" :to="{ name: 'returns' }">
                        Назад
                      </router-link>
                      <button class="btn btn-primary" @click="createReturn">Сохранить</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6">
                      <label class="title">Клиент:</label>
                      <v-select
                          v-if="users.length > 0"
                          v-model="clientReturn.user"
                          :options="users"
                          label="surname"
                          :disabled="clientReturn.user && clientReturn.items.length > 0"
                          :get-option-label="(option) => $can('manage', 'payments')
                            ? option.name + ' ' + option.surname
                            : option.id"
                      >
                        <template #option="{ name, surname, company_name }" v-if="$can('manage', 'payments')">
                          <span> {{ name }} {{ surname }} {{ company_name && `(${company_name})` }}</span>
                        </template>
                        <template #option="{ id }" v-else>
                          <span> {{ id }}</span>
                        </template>
                      </v-select>
                    </div>
                    <div class="form-group col-6">
                      <label class="title">Статус:</label>
                      <v-select
                          v-if="statuses.length > 0"
                          v-model="clientReturn.status"
                          :options="statuses"
                          label="name"
                      >
                      </v-select>
                    </div>
                  </div>
                  <hr class="invoice-spacing">
                  <h6 class="mb-1">Выбор товара</h6>
                  <div class="row">
                    <div class="col-6">
                      <label class="d-inline d-lg-none">Товар</label>
                      <v-select
                          v-model="selectedOrderItem"
                          :options="products"
                          :filterable="false"
                          label="id"
                          :disabled="!clientReturn.user"
                          class="item-selector-title"
                          placeholder="Выберите товар"
                          @search="searchProduct"
                      >
                        <template #selected-option="{ qty, product_specification }">
                          {{ product_specification.product.sku }} - Размер {{ product_specification.size_name }}
                        </template>
                        <template #option="{ qty, product_specification, return_qty, created_at }">
                          {{ product_specification.product.sku }} - Размер {{ product_specification.size_name }}
                          - {{ created_at | formattedDate }}
                          <br>
                          <small>{{ product_specification.product.name }} {{
                              product_specification.product.brand_name
                            }}</small><br>
                          <small>{{ product_specification.condition_name }}, Можно вернуть: {{
                              qty - return_qty
                            }} шт</small>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-6">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          :disabled="!clientReturn.user"
                          @click="addProduct"
                      >
                        Добавить товар
                      </b-button>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding form-item-section pt-0">
                  <div class="position-relative table-responsive">
                    <table class="table b-table">
                      <thead>
                      <tr>
                        <th>Товар</th>
                        <th>Фото</th>
                        <th>Размер</th>
                        <th>Состояние</th>
                        <th>К-во</th>
                        <th>Вес (кг)</th>
                        <th v-if="$can('read', 'payments')">Цена</th>
                        <th>Количество в заказе</th>
                        <th v-if="$can('read', 'payments')">Цена в заказе</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in clientReturn.items" :key="index">
                        <td>
                          <router-link
                              class="text-body"
                              :to="{name: 'product-details', params: { id: item.order_item.product_specification.product.id}}"
                          >
                            {{ item.order_item.product_specification.product.name }}
                            {{ item.order_item.product_specification.product.brand_name }}
                          </router-link>
                          <br/>
                          <small>Артикул: {{ item.order_item.product_specification.product.sku }}</small> <br/>
                        </td>
                        <td class="p-0">
                          <img
                              :src="`/img/product/${item.order_item.product_specification.product.small_main_image}`"
                              class="product-img" alt=""
                          >
                        </td>
                        <td class="text-uppercase">{{ item.order_item.product_specification.size_name }}</td>
                        <td>{{ item.order_item.product_specification.condition_name }}</td>
                        <td>
                          <input
                              type="number"
                              v-model="item.qty"
                              :max="item.order_item.qty"
                              min="1"
                              class="form-control qty-input"
                          >
                        </td>
                        <td>{{ item.order_item.product_specification.weight / 1000 }}</td>
                        <td v-if="$can('read', 'payments')">
                          {{ item.cost }} <span class="currency">{{ item.currency.name }}</span>
                        </td>
                        <td>{{ item.order_item.qty }}</td>
                        <td v-if="$can('read', 'payments')">
                          {{ item.order_item.cost }} <span class="currency">{{ item.order_item.currency.name }}</span>
                        </td>
                        <td>
                          <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                          />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
                <b-card-body class="invoice-padding pb-0">
                  <div class="row">
                    <div class="col-12 col-md-6 order-2 order-md-1 mt-md-0 mt-3 d-flex align-items-center"></div>
                    <div class="col-12 col-md-6 order-1 order-md-2 mt-md-6 d-flex justify-content-end">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Количество:</p>
                          <p class="invoice-total-amount">{{ summary.qty }}</p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Вес (кг):</p>
                          <p class="invoice-total-amount">{{ summary.weight / 1000 }}</p>
                        </div>
                        <hr class="my-50">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Сумма:</p>
                          <p class="invoice-total-amount">{{ summary.cost }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Комментарии: </span>
                  <b-form-textarea v-model="clientReturn.comments"/>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
  BCard,
  BCardBody,
  BButton,
  BFormTextarea,
  VBToggle,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'ReturnCreate',
  components: {
    BCard,
    BCardBody,
    BButton,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      users: [],
      statuses: [],
      selectedOrderItem: null,
      products: [],
      clientReturn: {
        user: null,
        status: null,
        items: [],
        comments: null,
      },
    };
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'order.items': function () {
      this.initTrHeight();
    },
  },
  async mounted() {
    await this.getUsers();
    await this.getReturnStatuses();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    async getUsers() {
      this.users = (await this.$api.users.list()).data;
    },
    async createReturn() {
      try {
        const response = await this.$api.returns.create({ ...this.clientReturn });
        this.$toast.success('Возврат успешно обновлен');
        await this.$router.push({ name: 'return-details', params: { id: response.data.id } });
      } catch (e) {
       // console.log(e);
      }
    },
    async searchProduct(query, loading) {
      if (query.length && this.clientReturn.user) {
        loading(true);
        this.$api.returns.searchProducts({
          search: query,
          user_id: this.clientReturn.user.id,
        }).then((response) => {
          this.products = response.data;
          loading(false);
        });
      }
    },
    async getReturnStatuses() {
      this.statuses = (await this.$api.orders.statuses()).data;
      this.clientReturn.status = this.statuses.find((status) => status.id === 2);
    },
    addProduct() {
      if (!this.clientReturn.items.find((item) => item.order_item.id === this.selectedOrderItem.id)) {
        this.clientReturn.items.push({
          order_item: {
            product_specification: this.selectedOrderItem.product_specification,
            cost: this.selectedOrderItem.cost,
            currency: this.selectedOrderItem.currency,
            qty: this.selectedOrderItem.qty,
            id: this.selectedOrderItem.id,
          },
          cost: this.selectedOrderItem.return_price,
          qty: 1,
          currency: this.selectedOrderItem.currency,
          max_qty: this.selectedOrderItem.qty - this.selectedOrderItem.return_qty,
        });
      }
    },
    removeItem(index) {
      this.clientReturn.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
      });
    },
  },
  computed: {
    summary() {
      return {
        qty: this.clientReturn.items.reduce((acc, item) => parseInt(acc, 10) + parseInt(item.qty, 10), 0),
        cost: this.clientReturn.items.reduce((acc, item) => acc + item.cost * item.qty, 0),
        weight: this.clientReturn.items.reduce((acc, item) => acc + item.order_item.product_specification.weight * item.qty, 0),
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.invoice-edit-input {
  max-width: 100% !important;
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

span.currency {
  font-size: 10px;
}

.product-img {
  max-height: 80px;
}

.qty-input {
  width: 100px;
}
</style>
